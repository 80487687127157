var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CContainer",
    { staticClass: "content-center min-vh-100" },
    [
      _c(
        "CRow",
        { staticClass: "justify-content-center" },
        [
          _c(
            "CCol",
            [
              _c(
                "CCard",
                { staticClass: "p-4" },
                [
                  _c(
                    "CCardBody",
                    [
                      _c(
                        "CForm",
                        {
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return _vm.handle($event)
                            }
                          }
                        },
                        [
                          _c("h1", [_vm._v("Passwort ändern")]),
                          _c("div", { staticClass: "form-group" }, [
                            _vm.message
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "alert alert-danger",
                                    attrs: { role: "alert" }
                                  },
                                  [_vm._v(" " + _vm._s(_vm.message) + " ")]
                                )
                              : _vm._e()
                          ]),
                          _c("ValidatedInput", {
                            attrs: {
                              label: "Aktuelles Passwort",
                              field: _vm.$v.user.password,
                              value: _vm.$v.user.password.$model,
                              autocomplete: "password",
                              type: "password",
                              "data-cy": "password"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.user.password,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("ValidatedInput", {
                            attrs: {
                              label: "Neues Passwort",
                              field: _vm.$v.user.newPassword,
                              value: _vm.$v.user.newPassword.$model,
                              autocomplete: "new-password",
                              type: "password",
                              "data-cy": "newPassword"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.user.newPassword,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("ValidatedInput", {
                            attrs: {
                              label: "Passwortwiederholung",
                              field: _vm.$v.user.newPasswordConfirmation,
                              value: _vm.$v.user.newPasswordConfirmation.$model,
                              autocomplete: "new-password",
                              type: "password",
                              "data-cy": "newPasswordConfirmation"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.user.newPasswordConfirmation,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c(
                            "CButton",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                color: "primary",
                                disabled: _vm.loading,
                                type: "submit"
                              }
                            },
                            [
                              _c("span", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.loading,
                                    expression: "loading"
                                  }
                                ],
                                staticClass: "spinner-border spinner-border-sm"
                              }),
                              _c("span", [_vm._v("Speichern")])
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }